<template>
  <div>
    <el-tabs v-model="activeNameSight" @tab-click="handleSightTabChange">
      <el-tab-pane label="收藏" name="favorite">
        <el-table :data="favorites" style="width: 100%" size="small">
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="省" prop="province"></el-table-column>
          <el-table-column label="城市" prop="city"></el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="goSightDetail(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="景点发布" name="sight">
        <el-table :data="sights" style="width: 100%" size="small">
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="状态" prop="stateText"></el-table-column>
          <el-table-column label="省" prop="province"></el-table-column>
          <el-table-column label="城市" prop="city"></el-table-column>
          <el-table-column label="类别" prop="typeName"></el-table-column>
          <el-table-column label="时间" prop="genTime"></el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="goSightDetail(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="位置发布" name="position">
        <el-table :data="positions" style="width: 100%" size="small">
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="景点" prop="sightName"></el-table-column>
          <el-table-column label="省" prop="province"></el-table-column>
          <el-table-column label="城市" prop="city"></el-table-column>
          <el-table-column label="类别" prop="typeName"></el-table-column>
          <el-table-column label="时间" prop="genTime"></el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="goSightDetail(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="私有位置" name="positionUser">
        <el-table :data="positionUsers" style="width: 100%" size="small">
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="adcode" prop="adcode"></el-table-column>
          <el-table-column label="城市" prop="city"></el-table-column>
          <el-table-column label="时间" prop="genTime"></el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <el-pagination
      :page-size="count"
      :pager-count="9"
      :current-page.sync="page"
      layout="total, prev, pager, next"
      :total="total"
      hide-on-single-page
      @current-change="handlePaginationChange"
    ></el-pagination>
  </div>
</template>

<script>
import { adminGetUserSightInfo } from "@/api/api";
export default {
  props: ["idUser"],
  data() {
    return {
      activeNameSight: "favorite",
      positions: [],
      favorites: [],
      sights: [],
      positionUsers: [],
      page: 1,
      total: 0,
      count: 30
    };
  },
  mounted() {
    this.adminGetUserSightInfo();
  },
  methods: {
    adminGetUserSightInfo() {
      const that = this;
      adminGetUserSightInfo({
        idUser: that.idUser,
        type: that.activeNameSight,
        page: that.page,
        count: that.count
      }).then(res => {
        that.total = res.data.total;
        switch (that.activeNameSight) {
          case "favorite":
            that.favorites = res.data.favorites;
            break;
          case "sight":
            that.sights = res.data.sights;
            break;
          case "position":
            that.positions = res.data.positions;
            break;
          case "positionUser":
            that.positionUsers = res.data.positionUsers;
            break;
        }
      });
    },
    handleSightTabChange(value) {
      this.page = 1;
      this.type = value;
      this.adminGetUserSightInfo();
    },
    handlePaginationChange(value) {
      this.page = value;
      this.adminGetUserSightInfo();
    },
    goSightDetail(e) {
      console.log(e,'1111111111111')
      this.$router.push({
        path: "/sights/sightdetail",
        query: { id: e.id }
      });
    }
  }
};
</script>

<style>
</style>