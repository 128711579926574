<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleTabChange">
      <el-tab-pane label="收藏" name="favorite"></el-tab-pane>
      <el-tab-pane label="个人" name="private"></el-tab-pane>
      <el-tab-pane label="发布" name="public"></el-tab-pane>
    </el-tabs>
    <el-table :data="routes" style="width: 100%" size="small">
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="行程" prop="duration"></el-table-column>
      <el-table-column label="类别" prop="stateText"></el-table-column>
      <el-table-column label="时间" prop="genTime"></el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="goRouteDetail(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :page-size="count"
      :pager-count="9"
      :current-page.sync="page"
      layout="total, prev, pager, next"
      :total="total"
      hide-on-single-page
      @current-change="handlePaginationChange"
    ></el-pagination>
  </div>
</template>

<script>
import { adminGetUserRouteInfo } from "@/api/api";
export default {
  props: ["idUser"],
  data() {
    return {
      activeName: "favorite",
      routes: [],
      page: 1,
      count: 30,
      total: 0
    };
  },
  mounted() {
    this.adminGetUserRouteInfoFn();
  },
  methods: {
    adminGetUserRouteInfoFn() {
      const that = this;
      adminGetUserRouteInfo({
        idUser: that.idUser,
        type: that.activeName,
        page: that.page,
        count: that.count
      }).then(res => {
        that.total = res.data.total;
        that.routes = res.data.routes;
      });
    },
    handleTabChange(value) {
      this.page = 1;
      this.type = value;
      this.adminGetUserRouteInfoFn();
    },
    handlePaginationChange(value) {
      this.page = value;
      this.adminGetUserSightInfo();
    },
    goRouteDetail(e) {
      this.$router.push({
        path: "/sightroutes/routedetail",
        query: { id: e.id }
      });
    }
  }
};
</script>

<style>
</style>