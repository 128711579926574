<template>
  <div>
    <el-tabs v-model="activeName">
      <div>
        <el-button type="warning" size="mini" @click="back">《 返回</el-button>
      </div>
      <el-tab-pane label="用户信息" name="user">
        <el-descriptions :title="user.nickName" :column="4" border style="margin: 40px 20px;">
          <template slot="extra" style="display:flex">
            <el-button type="primary" @click="messageCreateShow = true" size="mini">发送消息</el-button>
            <el-button type="primary" @click="sendCoupon()" size="mini">发送优惠券奖励</el-button>
            <!-- // 0，正常；1，禁用 -->
            <el-button v-if="user.state == 1" type="primary" size="mini" @click="freeAccount()">解禁</el-button>
            <el-button v-if="user.state == 0" type="danger" size="mini" @click="balckAccount()">禁用</el-button>
          </template>
          <el-descriptions-item v-for="(value, key) in tableList.user" :key="key" :label="value">
            <div v-if="key === 'avatarUrl'">
              <img :src="user[key]" alt height="40px" />
            </div>
            <div v-else>{{ user[key] }}</div>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="3" border style="margin: 40px 20px;">
          <el-descriptions-item v-for="(value, key) in tableList.userId" :key="key" :label="value">
            <div v-if="key === 'avatarUrl'">
              <img :src="user[key]" alt height="40px" />
            </div>
            <div v-else>{{ user[key] }}</div>
          </el-descriptions-item>
        </el-descriptions>

        <!-- 发送消息弹出框 -->
        <el-dialog :visible.sync="messageCreateShow" title="发送消息" destroy-on-close width="80%">
          <messagecreate :custId="idUser" @close="messageCreateShow = false" />
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane label="余额账户" name="account">
        <accountinfo :custId="idUser" :custName="nickName" v-if="activeName=='account'" />
      </el-tab-pane>
      <el-tab-pane label="优惠券" name="couponList">
        <el-tabs v-model="activeNameCoupon">
          <el-tab-pane label="未使用" name="1"></el-tab-pane>
          <el-tab-pane label="已消费" name="2"></el-tab-pane>
          <el-tab-pane label="已失效" name="3"></el-tab-pane>
        </el-tabs>
        <table>
          <thead>
            <td v-for="(item, index) in tableList.couponHead" :key="index">{{ item }}</td>
          </thead>
          <tr
            v-for="(item, index) in coupons.filter(item => item.state == this.activeNameCoupon)"
            :key="index"
          >
            <td v-for="(value, key) in tableList.couponHead" :key="key">{{ item[key] }}</td>
          </tr>
        </table>
      </el-tab-pane>
      <el-tab-pane label="订单" name="orders">
        <el-tabs v-model="activeNameOrder" @tab-click="handleOrderTabChange">
          <el-tab-pane label="完成订单" name="1"></el-tab-pane>
          <el-tab-pane label="预定订单" name="2"></el-tab-pane>
          <el-tab-pane label="退款订单" name="3"></el-tab-pane>
        </el-tabs>
        <orderlisttable :orders="orders" />
        <el-pagination
          :page-size="30"
          :pager-count="9"
          :current-page.sync="page"
          layout="total, prev, pager, next"
          :total="total"
          hide-on-single-page
          @current-change="handleCurrentChange"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="通知消息" name="message">
        <messagelist :custId="idUser" :custType="'user'" v-if="activeName=='message'" />
      </el-tab-pane>
      <el-tab-pane label="评论" name="comments">
        <commentpage custId="user" v-if="activeName=='comments'" />
      </el-tab-pane>
      <el-tab-pane label="景点" name="sights">
        <sightuser :idUser="idUser" v-if="activeName=='sights'" />
      </el-tab-pane>
      <el-tab-pane label="线路" name="routes">
        <routeuser :idUser="idUser" v-if="activeName=='routes'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import messagecreate from "../components/MessageCreat";
import accountinfo from "../components/AccountInfo";
import orderlisttable from "../components/OrderListTable";
import messagelist from "../components/MessageList";
import commentpage from "../components/CommentPage";
import sightuser from "../components/SightsUser.vue";
import routeuser from "../components/RouteUser.vue";
import {
  getUserInfoAdmin,
  modifyUserState,
  adminGetOrderList
} from "@/api/api";
export default {
  components: {
    messagecreate,
    accountinfo,
    orderlisttable,
    messagelist,
    commentpage,
    sightuser,
    routeuser,
  },

  data() {
    return {
      idUser: this.$route.query.idUser,
      user: {},
      messageCreateShow: false,
      activeName: "user",
      activeNameCoupon: "1", //优惠券的tab
      activeNameSight: "1",
      nickName: "",
      activeNameOrder: "1",
      page: 1,
      storeType: 0,
      state: 1,
      orders: [],
      total: 0,

      tableList: {
        user: {
          id: "SUBID",
          idUser: "IDUSER",
          regphoneUser: "电话",
          nickName: "昵称",
          avatarUrl: "头像",
          gender: "性别",
          is_subscribe: "关注十万八",
          district: "区县",
          city: "城市",
          province: "省",
          adcode: "区码",
          points: "积分",
          vipdate: "VIP",
          // logchannel: "app登录",
          logCounts: "登录次数",
          lastLogTime: "上次登陆时间",
          genTime: "创建时间"
        },
        userId: {
          unionid: "微信UID",
          miniOpenid: "微信小程序ID",
          wechatOpenid: "微信公众号ID",
          openid_douyin: "抖音ID",
          unionid_douyin: "抖音UID",
          openid_ks: "快手ID",
          openid_toutiao: "头条ID",
          unionid_toutiao: "头条UID",
          openid_douyinlite: "极速抖音ID",
          unionid_douyinlite: "极速抖音UID",
          openid_toutiaolite: "极速头条ID",
          unionid_toutiaolite: "极速头条UID"
        },

        couponHead: {
          couponName: "优惠券名称",
          couponCode: "券码",
          type: "类别",
          par: "面值",
          state: "状态",
          genTime: "生成时间",
          validStartDate: "有效期开始",
          validEndDate: "有效期结束",
          owner: "拥有人",
          remark: "备注",
          promotionId: "活动编号"
        }
      },
      //res.data
      coupons: [
        //该用户所有的优惠券
      ]
    };
  },

  mounted() {
    this.getUserInfoFn();
    this.getOrderListFn();
  },
  methods: {
    getUserInfoFn() {
      const that = this;
      getUserInfoAdmin({
        idUser: that.idUser
      }).then(res => {
        if (res.result) {
          that.user = res.data;
        }
      });
    },
    balckAccount() {
      const that = this;
      this.$prompt("请输入禁用的原因", "禁用账户", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\S{1,}$/,
        inputErrorMessage: "请输入原因",
        closeOnClickModal: false
      })
        .then(({ value }) => {
          modifyUserState({
            state: 1,
            idUser: that.idUser,
            remark: value
          }).then(res => {
            if (res.result) {
              that.$message({
                type: "success",
                message: "已禁用该账户"
              });
              that.getUserInfoFn();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消"
          });
        });
    },
    freeAccount() {
      const that = this;
      this.$confirm("确认解禁该账户？", "解禁", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          modifyUserState({
            state: 0,
            idUser: that.idUser
          }).then(res => {
            if (res.result) {
              that.$message({
                type: "success",
                message: "更改成功!"
              });
              that.getUserInfoFn();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消更改"
          });
        });
    },
    getOrderListFn() {
      const that = this;
      adminGetOrderList({
        page: that.page,
        storeType: that.storeType,
        state: that.state,
        idUser: that.idUser,
        idStore: ""
      }).then(res => {
        if (res.result) {
          let oldOrders = that.orders;
          that.orders = oldOrders.concat(res.data.orders);
          that.total = res.data.total;
        }
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getOrderListFn();
    },
    handleOrderTabChange() {
      switch (this.activeNameOrder) {
        case "1":
          this.page = 1;
          this.orders = [];
          this.storeType = 1;
          this.state = 1;
          break;

        case "2":
          this.page = 1;
          this.orders = [];
          this.storeType = 1;
          this.state = 0;
          break;

        case "3":
          this.page = 1;
          this.orders = [];
          this.storeType = 1;
          this.state = -1;
          break;
      }
      this.getOrderListFn();
    },
    back() {
      this.$router.back(-1);
    },
    sendCoupon() {
      this.$router.push({
        path: "/setbonus",
        query: { idUser: JSON.stringify(this.idUser) }
      });
    }
  }
};
</script>
<style scoped>
</style>